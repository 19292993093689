import React from "react";

const InnerHead = () => {
  return (
    <section
      className="hero banner d-flex align-items-center justify-content-center text-white text-center position-relative"
      style={{
        height: "70vh",
        overflow: "hidden",
      }}
    >
      <div
        className="background-image"
        style={{
          backgroundImage: `url(https://www.sviet.ac.in/_next/static/media/backdrop.a35c5ee3.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          filter: "blur(2px)",
          zIndex: -1,
        }}
      ></div>

      <div className="container mt-4 pt-4">
        <h1 className="">
          Third International Conference on Advanced Computing & Communication
          Technologies
        </h1>
        <p className="lead">(Hybrid Mode)</p>
        <div className="conference-date text-white py-2 px-4 mt-4 rounded">
          <h4>19th & 20th July 2025</h4>
        </div>
        <div className="conference-organised mt-3 h5 font-weight-medium">
          <h3>SVIET College, Banur, India</h3>
        </div>
      </div>
    </section>
  );
};

export default InnerHead;
