import ContactUs from "../components/ContactUs";
import InnerHead from "../components/InnerHead";
import Hero from "../components/Hero";
import Call from "../components/Call";

import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { client } from "../config/prismic";

const SpecialSession = () => {
  const [sessions, setSessions] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await client.getByType("special_session");
      setSessions(response.results.map((result) => result.data)); // Map to simplify access to session data
    };
    fetchData();
  }, []);

  if (!sessions) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  console.log(sessions); // Debugging the structure

  return (
    <div>
      <Header />
      <InnerHead />
      <div className="container my-3 mt-4 pt-4">
        <div
          className="section-title text-center position-relative pb-3 mb-2 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">
            Special Sessions
          </h5>
          {/* <h1 className="mb-0">Keynote Speakers</h1> */}
        </div>
        <div className="container my-5 py-3">
          {sessions.map((session, sessionIndex) => (
            <div key={sessionIndex} className="mb-4">
              {/* Session Title */}
              <h2 className="fw-bold text-center mb-4 text-orange">
                {session.title[0].text}
              </h2>

              <div className="d-flex flex-row justify-content-center flex-wrap">
                {session.speakers.map((speaker, speakerIndex) => (
                  <div className="col-md-3 text-center p-3" key={speakerIndex}>
                    <div className="card border-0">
                      <img
                        src={speaker.image.url}
                        alt={speaker.name[0].text}
                        className="card-img-top img-fluid"
                        style={{ maxHeight: "300px", objectFit: "cover" }}
                      />
                      <div className="card-body bg-dark-blue">
                        <h5 className="card-title font-weight-bold">
                          {speaker.name[0].text}
                        </h5>
                        <p className="card-text">
                          {speaker.designation[0].text}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default SpecialSession;
