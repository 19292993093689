import React from "react";

const AboutUs = () => {
  return (
    <div>
      <div className="container my-5 p-4 border rounded bg-light shadow-sm">
        <div
          className="section-title text-center position-relative pb-3 mb-2 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">
            About the Conference
          </h5>
          <h2 className="mb-0 w-full">Welcome to ICACCTech 2025</h2>
        </div>
        <hr />
        <p className="text-justify">
          <b>
            Novel Research Foundation is organizing Third International
            Conference on Advanced Computing & Communication Technologies (Third
            ICACCTech 2025) in association with SVIET college, Banur, India on
            19 - 20 July 2025 at Swami Vivekanand Institute of Engineering and
            Technology (SVIET) , Banur, Punjab, India
          </b>
          <br />
          <br />
          Third International Conference on Advanced Computing & Communication
          Technologies (ICACCTech 2025) promises to address current state of the
          technology and the outcome of the ongoing research in the area of
          advance computing and communication. Computer and communication has
          immensely enhanced the level of information acquisition, retrieval and
          processing. ICACCTech 2025 would offer a forum to the academicians,
          researchers and students to interact with experts in the area and to
          learn the recent knowledge in advance computing. We invite original &
          unpublished work from individuals active in the broad theme of the
          conference.
          <br />
          <b>
            Venue: Swami Vivekanand Institute of Engineering and Technology
            (SVIET) , Banur, Punjab, India
          </b>
          <br />
          <b>
            Conference proceedings will be published with ISBN number in
            electronic form by CPS and shall be submitted to Xplore and CSDL by
            CPS.
          </b>
        </p>
      </div>
      {/* <div className="container my-5">
        <div className="p-4 rounded bg-dark text-white shadow-sm">
          <h3 className="fw-bold">Conference Record Number - 65084</h3>
          <h3 className="fw-bold">
            <a href="https://www.ieee.org/conferences/organizers/conf-app.html?confRecNum=65084">
              Conference Record Number - 65084
            </a>
          </h3>
          <p>
            <b>
              All accepted papers will be submitted by CPS to the indexing
              companies for possible indexing (Scopus, Ei Compendex and other
              major Indexes).
            </b>
            <br />
            <br />
            1st International Conference on Advanced Computing & Communication
            Technologies was held at Swami Vivekanand Institute of Engineering
            and Technology was held at 23-24 December 2023.
            <br />
            <b>All papers of 1st ICACCTech 2023 were published in xplore.</b>
            <br />
            Link of 1st ICACCTech 2023 published papers:{" "}
            <a href="https://ieeexplore.ieee.org/xpl/conhome/10440965/proceeding">
              Xplore - Conference Table of Contents
            </a>
            <br />
            <br />
            2nd International Conference on Advanced Computing & Communication
            Technologies was held at Ethnic India, Rai, Sonipat, Delhi - NCR,
            India was held on 16th & 17th November 2024.
            <br />
            <b>All papers of 1st ICACCTech 2025 were published in xplore.</b>
            <br />
            Link of 1st ICACCTech 2023 published papers:{" "}
            <a href="https://ieeexplore.ieee.org/xpl/conhome/10440965/proceeding">
              Xplore - Conference Table of Contents
            </a>
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default AboutUs;
