import React, { useEffect, useState } from "react";
import { client } from "../config/prismic";

export const SpeakerCard = ({ speaker }) => (
  <div className="col-lg-3 col-md-4 col-sm-6 my-4">
    <div className="card shadow-sm">
      <div className="overflow-hidden">
        <img className="card-img-top" src={speaker.image} alt={speaker.name} />
      </div>
      <div className="card-body text-center">
        <h5 className="card-title text-primary">{speaker.name}</h5>
        <p className="card-text">{speaker.designation}</p>
        <a className="text-danger" href={`mailto:${speaker.email}`}>
          {speaker.email}
        </a>
      </div>
    </div>
  </div>
);

const Speaker = () => {
  const [speakers, setSpeakers] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await client.getByType("keynote_speakers");
      setSpeakers(response.results[0]?.data.collection); // Accessing the first result's collection directly
    };
    fetchData();
  }, []);

  if (!speakers) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="container my-3">
      <div
        className="section-title text-center position-relative pb-3 mb-2 mx-auto"
        style={{ maxWidth: "600px" }}
      >
        <h5 className="fw-bold text-primary text-uppercase">
          {Array.isArray(speakers) && speakers.length > 0 && "Keynote Speakers"}
        </h5>
        {/* <h1 className="mb-0">Keynote Speakers</h1> */}
      </div>
      <div className="d-flex flex-row justify-content-center flex-wrap">
        {speakers.map((speaker, index) => (
          <div className="col-md-3 text-center p-3" key={index}>
            <div className="card border-0">
              <img
                src={speaker.image.url}
                alt={speaker.name[0].text}
                className="card-img-top img-fluid"
                style={{ maxHeight: "300px", objectFit: "cover" }}
              />
              <div className="card-body bg-dark-blue">
                <h5 className="card-title font-weight-bold">
                  {speaker.name[0].text}
                </h5>
                <p className="card-text">{speaker.designation[0].text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Speaker;
