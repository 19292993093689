import React, { useEffect, useState } from "react";
import { client } from "../config/prismic";

const Dates = ({ dateRef }) => {
  const [dates, setDates] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await client.getByType("dates");
      setDates(response.results[0]?.data); // Accessing the first result's data directly
    };
    fetchData();
  }, []);

  if (!dates) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }
  return (
    <div ref={dateRef} pt-lg-5>
      <div className="container-fluid facts py-5 pt-lg-2 mt-lg-5 pb-5">
        <div className="container py-5 pt-lg-0">
          <div
            className="section-title text-center position-relative pb-3 mb-2 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">Timeline</h5>
            <h1 className="mb-0">Important Dates</h1>
          </div>
          <div className="row gx-0">
            <div className="col-lg-4 wow zoomIn px-2" data-wow-delay="0.1s">
              <div
                className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-calendar text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Paper Submission Start</h5>
                  <h3 className="text-white mb-0" data-toggle="counter-up">
                    {dates.paper_submission[0].text}
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn px-2" data-wow-delay="0.3s">
              <div
                className="bg-light shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-calendar text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-primary mb-0">
                    Paper Submission Deadline
                  </h5>
                  <h3 className="mb-0" data-toggle="counter-up">
                    {dates.paper_submission_end_date[0].text}
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn px-2" data-wow-delay="0.6s">
              <div
                className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-calendar text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">
                    Authors Notified of Final Acceptance
                  </h5>
                  <h3 className="text-white mb-0" data-toggle="counter-up">
                    {dates.acceptance[0].text}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-0 my-4 flex justify-content-center">
            <div className="col-lg-4 wow zoomIn px-2" data-wow-delay="0.6s">
              <div
                className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-calendar text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">
                    Camera-Ready Paper Submissions and Copyrights Due
                  </h5>
                  <h3 className="text-white mb-0" data-toggle="counter-up">
                    {dates.camera_ready_paper[0].text}
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn px-2" data-wow-delay="0.3s">
              <div
                className="bg-light shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-calendar text-white"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-primary mb-0">
                    Last date for Registration
                  </h5>
                  <h3 className="mb-0" data-toggle="counter-up">
                    {dates.last_date_registration[0].text}
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn px-2" data-wow-delay="0.6s">
              <div
                className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                style={{ height: "150px" }}
              >
                <div
                  className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                  style={{ width: "60px", height: "60px" }}
                >
                  <i className="fa fa-calendar text-primary"></i>
                </div>
                <div className="ps-4">
                  <h5 className="text-white mb-0">Conference Date</h5>
                  <h3 className="text-white mb-0" data-toggle="counter-up">
                    {dates.conference_date[0].text}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dates;
