import React, { useEffect, useState } from "react";
import { client } from "../config/prismic";

const Sponsors = () => {
  return (
    <div className="container my-3">
      <div
        className="section-title text-center position-relative pb-3 mb-2 mx-auto"
        style={{ maxWidth: "600px" }}
      >
        {/* <h5 className="fw-bold text-primary text-uppercase">Sponsors</h5> */}
        <h1 className="mb-0">Sponsors</h1>
      </div>
      <div className="d-flex flex-row justify-content-center flex-wrap">
        <div className="text-center p-3">
          <div className="card border-0">
            <img
              src="https://www.sviet.ac.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FLogo.8bdb37ea.webp&w=640&q=75"
              className="card-img-top img-fluid"
              style={{ maxHeight: "80px", objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
