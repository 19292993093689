import * as prismic from "@prismicio/client";

// Define your Prismic repository name
const repositoryName = "icactech-25";

// Initialize the Prismic client
export const client = prismic.createClient(repositoryName, {
  accessToken:
    "MC5aMUhYdnhFQUFDZ0EzeThr.77-9G--_vVbvv73vv73vv70p77-977-9Be-_vTMP77-977-977-977-977-977-9GW7vv71nSiXvv71J77-9Ue-_vR0", // Optional if the repository is private
});
