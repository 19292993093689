import React from "react";

const Footer = () => {
  return (
    <div>
      <div
        className="container-fluid footer-text"
        style={{ background: "white" }}
      >
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center py-2"
                style={{ height: "33px" }}
              >
                <p className="mb-0">&copy; ICACCTech. All Rights Reserved.</p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
