// import React from "react";
// import Header from "../components/Header";
// import Hero from "../components/Hero";
// import AdvisoryCommittee from "../components/AdvisoryCommittee";
// import InnerHead from "../components/InnerHead";

// const Committee = () => {
//   return (
//     <div>
//       <Header />
//       <InnerHead />
//       <AdvisoryCommittee />
//     </div>
//   );
// };

// export default Committee;

import Header from "../components/Header";
import Footer from "../components/Footer";
import InnerHead from "../components/InnerHead";
import { client } from "../config/prismic";
import { useEffect, useState } from "react";

const Committee = () => {
  const [sessions, setSessions] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await client.getByType("committee");
      setSessions(response.results);
    };
    fetchData();
  }, []);

  if (!sessions) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  const renderTextWithBoldFirstLine = (text) => {
    // Split text by newlines
    const lines = text.split("\n");

    // Only focus on the first line for bold
    const firstLine = lines[0];
    const restOfText = lines.slice(1).map((line, index) => (
      <span key={index}>
        {line}
        {index < lines.length - 2 && <br />}{" "}
        {/* Add line break between subsequent lines */}
      </span>
    ));

    return (
      <>
        <strong>{firstLine}</strong>
        {restOfText.length > 0 && (
          <>
            <br />
            {restOfText}
          </>
        )}{" "}
        {/* Add a line break before the rest if there's any */}
      </>
    );
  };

  return (
    <div>
      <Header />
      <InnerHead />
      <div className="container section-title mt-4 pt-4" data-aos="fade-up">
        <h2 className="">Committee Members</h2>
      </div>
      <div className="container my-5">
        <div className="card shadow-lg">
          <div className="card-body">
            {sessions.map((session, sessionIndex) => (
              <div
                key={session.id}
                className={sessionIndex < sessions.length - 1 ? "mb-5" : ""}
              >
                {session.data.committee.map((item, index) => (
                  <div key={index} className="mb-3">
                    {item.type === "heading4" && (
                      <h4>{renderTextWithBoldFirstLine(item.text)}</h4>
                    )}
                    {item.type === "heading5" && (
                      <h5>{renderTextWithBoldFirstLine(item.text)}</h5>
                    )}
                    {item.type === "paragraph" && (
                      <p className="card-text text-justify">
                        {(item.text)}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Committee;

